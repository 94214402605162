exports.install = function (Vue, options) {

  /*跳转页面*/
  Vue.prototype.goHref = function (name,data) {
    this.$router.push({name: name,params: {data}})
  };
  /*跳转上一页面*/
  Vue.prototype.goHrefTop = function (name,data) {
    this.$router.go(-1)
  };
  //跳转页面传值给store
  Vue.prototype.goHrefS = function (name,key,data) {
    console.log('goHrefS',name,key,data)
    this.$store.state.login[key]= data
    this.$router.push({name: name})
  };

  Vue.prototype.goHrefS1 = function (name,key,data) {
    console.log('goHrefS',name,key,data)
    this.$store.state.login[key]= data
    //this.$router.push({name: name})
  };

  /*跳转上一页*/
  Vue.prototype.goPageTop = function (name) {
    this.$store.state.login.pageValue--
    this.$router.push({name: name})
  };
  /*获取数组对象的某个属性值*/
  Vue.prototype.getDataItem = function (data,key) {
    let s=[]
    if(data){
      data.forEach(data=>{
        s.push(data[key])
      })
    }
    return s.join(',')
  };
  /*除1000，取整*/
  Vue.prototype.c1000 = function (data) {
  return data?Math.floor(data/1000):''
  };

  //判断手机号格式
  Vue.prototype.toPhone= function (tel){
    var mobile = /^1[1|2|3|4|5|6|7|8|9]\d{9}$/ , phone = /^0\d{2,3}-?\d{7,8}$/;
    return mobile.test(tel) || phone.test(tel)
  };
  /*获取八大行业对应的页面*/
  Vue.prototype.getIndustry = function (name) {
    switch (name) {
      case '机械':
        return 'industryMach';
      case '塑料':
        return 'industryPlastic';
      case '纺织':
        return 'industrySpin';
      case '食品':
        return 'industryFood';
      case '建材':
        return 'industryBuilding';
      case '纸制品':
        return 'industryPaper';
      case '商贸':
        return 'industryTrade';
      case '轻工':
        return 'industryFurniture';
      case '家具':
        return 'industryFurniture';
      case '烟草':
        return 'industryMach';
      case '冶金':
        return 'industryMach';
      case '有色':
        return 'industryMach';
      default:
        return '';
    }
  };

  //判断手机号格式
  Vue.prototype.toPhone= function (phone){
    return  phone.toString().length===11
  };
  //下载附件
  Vue.prototype.fileDown= function (url){
    if (url)
    window.open(url)
  };
  //判断是否为图片
  Vue.prototype.isImg= function (value){
    let state=false
      if (/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(value)) {
        state=true;
      }
    return state;
  };
  //判断是否为视频
  Vue.prototype.isVideo= function (value){
    let state=false
    if (/\.(mp4|rmvb|avi|mov|rm|flv|wmv)$/.test(value)) {
      state=true;
    }
    return state;
  };
  //生产经营状态转换(参数：生成经营状态和停产停业原因)
  Vue.prototype.produceTo= function (data,stopReason){
    if(data==1){
      return '正常经营'
    }else if(data==2){
      if(stopReason==1){
        return '停产停业-正常放假'
      }else if(stopReason==2){
        return '停产停业-应急部门责令停产'
      }else if(stopReason==3){
        return '停产停业-其他部门责令停产'
      }else if(stopReason==4){
        return '停产停业-拟停产搬迁'
      }else {
        return '停产停业'
      }
    }else if(data==3){
      return '关闭(注销/搬迁)'
    }
  };
  //element手机号验证规则
  Vue.prototype.elePhone= function (phone){
    let phoneTo = (rule, value, callback) => {
      if (value===''||!value) {
        callback(new Error('必填'));
      }
      else if (value.toString().length!==11) {
        callback(new Error('手机号长度应为11位'));
      }else {
        callback();
      }
    };
    return  phoneTo
  };
  //element手机号验证规则-可以为空
  Vue.prototype.elePhoneNull= function (phone){
    let phoneTo = (rule, value, callback) => {
      if (value===''||!value) {
        callback();
      }
      else if (value.toString().length!==11) {
        callback(new Error('手机号长度应为11位'));
      }else {
        callback();
      }
    };
    return  phoneTo
  };
  //element验证规则-最小值
  Vue.prototype.numMin= function (num){
    let numMin = (rule, value, callback) => {
      if (value===''||!value) {
        callback(new Error('必填'));
      }
      else if (value<=num) {
        callback(new Error(`需大于${num}`));
      }else {
        callback();
      }
    };
    return  numMin
  };
  //element社信代码验证规则
  Vue.prototype.eleCode= function (){
    let codeTo = (rule, value, callback) => {
      if (value==='') {
        callback(new Error('必填'));
      }
      else if (value.toString().length!==18) {
        callback(new Error('社信代码长度应为18位'));
      }else {
        callback();
      }
    };
    return  codeTo
  };
  //element社信代码验证规则-可以为空
  Vue.prototype.eleCodeNull= function (){
    let codeTo = (rule, value, callback) => {
      if (value==='') {
        callback();
      }
      else if (value.toString().length!==18) {
        callback(new Error('社信代码长度应为18位'));
      }else {
        callback();
      }
    };
    return  codeTo
  };
  //字节转换
  Vue.prototype.sizeTo= function (limit){
      var size = "";
     if(limit<=0||!limit){
       size="-"
     } else if(limit < 0.1 * 1024){                            //小于0.1KB，则转化成B
        size = limit + "B"
      }else if(limit < 0.1 * 1024 * 1024){            //小于0.1MB，则转化成KB
        size = (limit/1024).toFixed(2) + "KB"
      }else if(limit < 0.1 * 1024 * 1024 * 1024){        //小于0.1GB，则转化成MB
        size = (limit/(1024 * 1024)).toFixed(2) + "MB"
      }else{                                            //其他转化成GB
        size = (limit/(1024 * 1024 * 1024)).toFixed(2) + "GB"
      }
      var sizeStr = size + "";                        //转成字符串
      var index = sizeStr.indexOf(".");                    //获取小数点处的索引
      var dou = sizeStr.substr(index + 1 ,2)            //获取小数点后两位的值
      if(dou == "00"){                                //判断后两位是否为00，如果是则删除00
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
      }
      return size;
  };
  /* 时间戳转字符型日期*/
  Vue.prototype.toDate = function (date) {
    if(!date){
      return "无"
    }
    if(date>9999999999){
      date=parseInt(date/1000)
    }
    Date.prototype.toLocaleString = function() {
      return this.getFullYear() + "-" + getzf(this.getMonth() + 1) + "-" +getzf(this.getDate()) + " " +getzf( this.getHours()) + ":" + getzf(this.getMinutes()) + ":" +getzf(this.getSeconds()) ;
    };
    var unixTimestamp = new Date(date * 1000)
    return unixTimestamp.toLocaleString();

  };
  /* 时间戳转字符型日期-年月*/
  Vue.prototype.toDateM = function (date) {
    if(!date){
      return "无"
    }
    if(date>9999999999){
      date=parseInt(date/1000)
    }
    Date.prototype.toLocaleString = function() {
      return this.getFullYear() + "-" + getzf(this.getMonth() + 1)  ;
    };
    var unixTimestamp = new Date(date * 1000)
    return unixTimestamp.toLocaleString();

  };
  /* 时间戳转字符型日期-年月日*/
  Vue.prototype.toDateD = function (date) {
    if(!date){
      return "无"
    }
    if(date>9999999999){
      date=parseInt(date/1000)
    }
    Date.prototype.toLocaleString = function() {
      return this.getFullYear() + "-" + getzf(this.getMonth() + 1) + "-" +getzf(this.getDate())  ;
    };
    var unixTimestamp = new Date(date * 1000)
    return unixTimestamp.toLocaleString();

  };
  //补0操作
  function getzf(num){
    if(parseInt(num) < 10){
      num = '0'+num;
    }
    return num;
  }
  /* 时间戳转字符型日期*/
  Vue.prototype.formatDate  = function (date) {
    if(!date){
      return "无"
    }
    if(date>9999999999){
      date=parseInt(date/1000)
    }
    Date.prototype.toLocaleString = function() {
      return this.getFullYear() + "-" + (this.getMonth() + 1) + "-" + this.getDate() + " " + this.getHours() + ":" + this.getMinutes() + ":" + this.getSeconds();
    };
    var unixTimestamp = new Date(date * 1000)
    return unixTimestamp.toLocaleString();
  };

  //获取Cookie
  Vue.prototype.getCookie = function (name) {
    name = name + "="
    var start = document.cookie.indexOf(name),
      value = null;
    if (start > -1) {
      var end = document.cookie.indexOf(";", start);
      if (end == -1) {
        end = document.cookie.length;
      }
      value = document.cookie.substring(start + name.length, end);
    }
    return value;
  };
  //设置Cookie
  Vue.prototype. setCookie=function(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    console.info(cname + "=" + '111' + "; " + expires);
    document.cookie = cname + "=" + cvalue + "; " + expires;
  };

};
