import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";

Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'Home',
		redirect: '/User',
		component: resolve => require(['@/page/home'], resolve),
		children: [{
				path: '/User',
				name: 'UserManage',
				component: resolve => require(['@/page/userManager/UserManage'], resolve),
			},
			{
				path: '/UserCharts',
				name: 'UserCharts',
				component: resolve => require(['@/page/userManager/UserCharts'], resolve),
			},
			// 充值记录
			{
				path: '/PayRecord',
				name: 'PayRecord',
				component: resolve => require(['@/page/payRecord/PayRecord'], resolve),
			},
			// 充值记录折线图
			{
				path: '/PayRecordCharts',
				name: 'PayRecordCharts',
				component: resolve => require(['@/page/payRecord/PayRecordCharts'], resolve),
			},
			// 二维码列表
			{
				path: '/QrCode',
				name: 'QrCode',
				component: resolve => require(['@/page/qrManager/QrCode'], resolve),
			},
			// App列表
			{
				path: '/AppList',
				name: 'AppList',
				component: resolve => require(['@/page/appManager/AppList'], resolve),
			},
			{
				path: '/AppList1',
				name: 'AppList1',
				component: resolve => require(['@/page/appManager/AppList1'], resolve),
			},
			{
				path: '/UpdatePushApp',
				name: 'UpdatePushApp',
				component: resolve => require(['@/page/appManager/UpdatePushApp'], resolve),
			},
			// app 用户列表
			{
				path: '/AppUser',
				name: 'AppUser',
				component: resolve => require(['@/page/appUserManager/AppUser'], resolve),
			},
			//
			{
				path: '/App_sc',
				name: 'App_sc',
				component: resolve => require(['@/page/appUserManager/App_sc'], resolve),
			},
			//
			{
				path: '/App_sc1',
				name: 'App_sc1',
				component: resolve => require(['@/page/appUserManager/App_sc1'], resolve),
			},

		]
	},
	{
		path: '/Login',
		name: 'Login',
		component: resolve => require(['@/page/Login'], resolve),
	}
]


const router = new VueRouter({
	mode: 'hash',
	routes
})

router.beforeEach((to, from, next) => {
	// console.log(to)
	// console.log(from)
	// return
	if (to.path === '/login') {
		next();
	} else {
		var token = sessionStorage.getItem('token')
		console.log(sessionStorage.getItem('token'), '这')
		if (token == null || token == '') {
			next('/login');
		} else {
			next();
		}
	}
});

export default router
