import axios from 'axios'
import store from '../store/index'
if(sessionStorage.getItem("token") != '' && sessionStorage.getItem("token") != null ){
    console.log(sessionStorage.getItem("token"),'有')
    axios.defaults.headers.common['token'] = sessionStorage.getItem("token")
}

export function myHttp(url, method, params = {}) {
    // 首先判断是get请求还是post请求
    axios.defaults.headers.common['token'] = sessionStorage.getItem("token") || ''
    let data = method.toLocaleLowerCase() === 'post' ? 'data' : 'params';
    return axios({
        method,
        url: global.posturl.host + global.posturl[url],
        [data]: params // 差异点在于data的值
    }).then((res) => {
        return Promise.resolve(res.data);
    }).catch((err) => {
        return Promise.reject(err);
    })
}


export function myPost(url, data = {}) {
    return new Promise((resolve, reject) => {
        //axios.defaults.headers.common['token'] = this.$store.state.token || ''
        let _this = this
        // console.log(`${url}接口发起请求，请求参数：`, data)
        axios.post(global.posturl.host + global.posturl[url], data)
            .then(response => {
                resolve(response.data);
                if(response.data.msg && response.data.msg != ''){
                    _this.$message({
                        type: 'success',
                        message: response.data.msg,
                        duration: 3000
                    })
                }
            }, err => {
                reject(err);
                _this.$message({
                    type: 'error',
                    message: '网络错误1',
                    duration: 5000
                });
            }).catch(() => {
            _this.$message({
                type: 'error',
                message: '网络错误2',
                duration: 5000
            });
        })
    })
}

export function myGet(url) {
    return new Promise((resolve, reject) => {
        //axios.defaults.headers.common['token'] = this.$store.state.token || ''
        let _this = this
        // console.log(`${url}接口发起请求，请求参数：`, data)
        axios.get(global.posturl.host + global.posturl[url])
            .then(response => {
                resolve(response.data);
                if(response.data.msg && response.data.msg != ''){
                    _this.$message({
                        type: 'success',
                        message: response.data.message,
                        duration: 3000
                    })
                }
            }, err => {
                reject(err);
                _this.$message({
                    type: 'error',
                    message: '网络错误1',
                    duration: 5000
                });
            }).catch(() => {
            _this.$message({
                type: 'error',
                message: '网络错误2',
                duration: 5000
            });
        })
    })
}
