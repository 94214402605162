var posturl = {
    // host: 'http://39.101.206.139:85',
    // host: 'https://sofa.cqxddwl.com',
	host:'https://charge.cheersofa.com',
    // 管理员登录
    login: '/index/login',
    //  管理员登出
    logout: '/index/logout',
    // 根据token获取用户信息,
    getInfo: '/index/info',
    // 分页查询用户
    userPage: '/user/page',
    // 分页充值记录
    payRecord: '/recharge/record/page',
    // 分页二维码
    qrCode: '/test/page',
    // 添加二维码
    qrAdd: '/test/save',
    // 修改二维码
    qrUpdate: '/test/update',
    // 删除二维码,
    qrDelete: '/test/delete/',
    // 分页APP
    appPage: '/app/page',
    // 添加APP
    appAdd: '/app/save',
    // 修改APP
    appUpdate: '/app/update',
    // 删除APP
    appDelete: '/app/delete/',
    // 充值统计
    payStatistics: '/recharge/statistics',
    // 用户统计
    userStatistics: '/user/statistics',
	// app 分页查询
	apppage:'/app/usr/page',
	// app 用户统计
	appsc:'/app/usr/statistics',
	// 删除小程序用户
	dell:'/user/delete/',
	// 添加更新app
	addnewapp:'/app/version/save',
	// 添加app列表
	addapppage:'/app/version/page',
	// 修改更新app
	updatenewapp:'/app/version/update',
	// 操作次数
	Czcs:'/app/usr/use/statistics'
}

global.posturl=posturl
