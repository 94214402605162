import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import echarts from 'echarts'
import './utils/url'
import {myPost,myGet, myHttp} from './utils/http'
import axios from "axios";
import base from './utils/base'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme


Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$post = myPost
Vue.prototype.$get = myGet
Vue.prototype.$ajax = myHttp
Vue.prototype.$http = axios

Vue.use(base)
Vue.use(VueQuillEditor, /* { default global options } */)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
